export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
export const TABLET = 'tablet';

export const DESKTOP_MEDIA = 'screen and (min-width: 992px)';
export const MOBILE_MEDIA = 'screen and (max-width: 575px)';
export const TABLET_MEDIA =
  'screen and (min-width: 576px) and (max-width: 991px)';

export const RESPONSIVE_MARKERS = [
  {
    name: MOBILE,
    media: MOBILE_MEDIA,
  },
  {
    name: TABLET,
    media: TABLET_MEDIA,
  },
  {
    name: DESKTOP,
    media: DESKTOP_MEDIA,
  },
];

/**
 * @description Get the active layout for the page
 *
 * @returns {String}
 */
export const getActiveLayout = (viewportWidth) => {
  if (viewportWidth < 576) {
    return MOBILE;
  }

  if (viewportWidth < 992) {
    return TABLET;
  }

  return DESKTOP;
};
